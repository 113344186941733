import React, { useState } from 'react';
import QuestionTitle from '../../../../Shared/Components/QuestionTitle/QuestionTitle';
import QuestionButton from '../../../../Shared/Components/Button/QuestionButton';

import SurveyChoiceOption from '../../../../Models/Survey/SurveyChoiceOption';
import LanguageHelper from '../../../../Utils/LanguageHelper';
import { SurveyMultipleChoiceQuestionType } from '../SurveyQuestionTypes';

import '../SurveyQuestion.scss';

const MultipleChoiceQuestion = ({
  question,
  questionKey,
  options,
  onAnswerSelect,
  previousAnswer,
  surveyId,
}: SurveyMultipleChoiceQuestionType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>(previousAnswer);

  const handleClick = (key: string, label: string) => {
    setSelectedAnswer(key);

    onAnswerSelect({
      optionKey: key,
      textValue: label,
      questionKey,
      questionLabel: question,
      surveyId,
    });
  };

  return (
    <div className="content-container">
      <div className="survey">
        <div className="survey-question">
          <div className="survey-question__title">
            <QuestionTitle title={question} />
          </div>
          <div className="survey-question__answers">
            <div className="survey-question__multiple-choice">
              {options.map((option: SurveyChoiceOption) => (
                <QuestionButton
                  key={option.key}
                  label={LanguageHelper.SelectLanguage(option.labelText)}
                  active={selectedAnswer === option.key}
                  onClick={() => handleClick(option.key!, LanguageHelper.SelectLanguage(option.labelText))}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleChoiceQuestion;
