import React, { useContext, ReactNode, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import DashboardContent from '../Models/Dashboard/DashboardContent';
import FilterLevel from '../Shared/Enums/FilterLevel';
import EditItem from '../Models/Interfaces/EditItem';

export const FilterFilledInData = React.createContext({
  filterData: {} as DashboardContent,
  items: {} as EditItem[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFilterData: (filterData: DashboardContent) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetFilterData: (keepFilterOne?: boolean) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setItemData: (surveyInformation: EditItem[]) => {},
});

export const useFilledInFilterData = () => useContext(FilterFilledInData);

type PropType = {
  children: ReactNode[] | ReactNode;
};

export const FilterFilledInDataProvider = (props: PropType) => {
  const { t } = useTranslation();

  const initialState: DashboardContent = {
    scopeFilter: [
      {
        customer: {
          value: null,
          label: t('dashboard.filters.customer'),
        },
        organisation: {
          value: null,
          label: t('dashboard.filters.organisation'),
        },
        division: {
          value: null,
          label: t('dashboard.filters.division'),
        },
        businessUnit: {
          value: null,
          label: t('dashboard.filters.businessunit'),
        },
        unit: {
          value: null,
          label: t('dashboard.filters.unit'),
        },
        team: {
          value: null,
          label: t('dashboard.filters.team'),
        },
      },
      {
        customer: {
          value: null,
          label: t('dashboard.filters.customer'),
        },
        organisation: {
          value: null,
          label: t('dashboard.filters.organisation'),
        },
        division: {
          value: null,
          label: t('dashboard.filters.division'),
        },
        businessUnit: {
          value: null,
          label: t('dashboard.filters.businessunit'),
        },
        unit: {
          value: null,
          label: t('dashboard.filters.unit'),
        },
        team: {
          value: null,
          label: t('dashboard.filters.team'),
        },
        self: {
          value: null,
          label: t('dashboard.filters.self'),
        },
      },
    ],
    filterDataQuery: {
      Filter1: {
        Level: FilterLevel.ORGANISATION,
        Id: null,
        DateFilters: [],
      },
      Filter2: {
        Level: null,
        Id: null,
        DateFilters: [],
      },
    },
  };

  const [filterData, updateFilterData] = useState<DashboardContent>(initialState);
  const [items, updateItemData] = useState<EditItem[]>([{ Id: '', Name: '' }]);

  const resetFilterData = (keepFilterOne: boolean = false) => {
    if (keepFilterOne) {
      // eslint-disable-next-line prefer-destructuring
      initialState.scopeFilter[0] = filterData.scopeFilter[0];
    }

    updateFilterData(initialState);
  };

  const setFilterData = (newFilterData: DashboardContent) => {
    const updateFilterScopeData = (index: number) => {
      const { self, team, unit, businessUnit, division, organisation } = newFilterData.scopeFilter[index];
      let filterId;
      let filterLevel;

      if (self?.value) {
        filterId = self?.value || null;
        filterLevel = FilterLevel.SELF;
      } else if (team?.value) {
        filterId = team.value;
        filterLevel = FilterLevel.TEAM;
      } else if (unit?.value) {
        filterId = unit.value;
        filterLevel = FilterLevel.UNIT;
      } else if (businessUnit?.value) {
        filterId = businessUnit.value;
        filterLevel = FilterLevel.BUSINESS_UNIT;
      } else if (division?.value) {
        filterId = division.value;
        filterLevel = FilterLevel.DIVISION;
      } else {
        filterId = organisation.value;
        filterLevel = FilterLevel.ORGANISATION;
      }

      return {
        Level: filterLevel,
        Id: filterId,
        DateFilters:
          index === 0
            ? newFilterData.filterDataQuery.Filter1.DateFilters
            : newFilterData.filterDataQuery.Filter2.DateFilters,
      };
    };

    filterData.filterDataQuery = {
      Filter1: updateFilterScopeData(0),
      Filter2: updateFilterScopeData(1),
    };

    updateFilterData({ ...filterData });
  };

  const setItemData = (data: EditItem[]) => {
    updateItemData(data);
  };

  const { children } = props;
  const context = useMemo(
    () => ({ filterData, setFilterData, items, setItemData, resetFilterData }),
    [filterData, items],
  );

  return <FilterFilledInData.Provider value={context}>{children}</FilterFilledInData.Provider>;
};
