import React from 'react';

import { DataResult, State, toODataString } from '@progress/kendo-data-query';
import http from '../../../../Utils/HttpClient';
import Loader from '../../Loader/Loader';
import Level from '../../../Enums/Level';

interface DataLoaderProps {
  gridLayer: Level | undefined;
  dataState: State;
  onDataReceived: (products: DataResult) => void;
}

const DataLoader = (props: DataLoaderProps) => {
  const { gridLayer } = props;
  const init = { method: 'GET', accept: 'application/json', headers: {} };

  const lastSuccess = React.useRef<string>('');
  const pending = React.useRef<string>('');

  const requestDataIfNeeded = () => {
    const { dataState, onDataReceived } = props;

    if (pending.current || toODataString(dataState) === lastSuccess.current) {
      return;
    }

    pending.current = toODataString(dataState);

    let url = `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION}/${gridLayer}?$count=true&${pending.current}`;
    url = url.replace("'guid", ''); // We are experiencing an oData loophole.
    url = url.replace("guid'", ''); // We are experiencing an oData loophole.

    http.get(url, init).then((response) => {
      lastSuccess.current = pending.current;
      pending.current = '';

      if (toODataString(dataState) === lastSuccess.current) {
        onDataReceived.call(undefined, {
          data: response.data.value,
          total: response.data['@odata.count'],
        });
      } else {
        requestDataIfNeeded();
      }
    });
  };

  requestDataIfNeeded();
  return pending.current ? <Loader /> : null;
};

export default DataLoader;
