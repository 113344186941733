import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../../Shared/Components/Button/Button';
import Modal from './Modal';
import { deactivateUser } from '../../../Shared/Data/GetUserService';
import ButtonTheme from '../../../Shared/Enums/ButtonTheme';

import './UserSettings.scss';

const DeactivateAccount = () => {
  const [showModal, setShowModal] = useState(false);
  const { userId } = JSON.parse(localStorage.getItem('user')!);
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const handleClicked = () => {
    deactivateUser(userId).then(() => {
      localStorage.removeItem('acceptedTerms');
      logout();
    });
  };

  useEffect(() => {}, [showModal]);

  return (
    <div className="content-container">
      <div className="user-settings">
        <h1>{t('usersettings.deactivate-account.title')}</h1>
        <p>{t('usersettings.deactivate-account.description')}</p>

        <Button
          onClick={() => setShowModal(true)}
          theme={ButtonTheme.DANGER}
          label={t('usersettings.deactivate-account.button')!}
        />
      </div>
      <Modal
        showModal={showModal}
        modalTitle={t('usersettings.modal.are-you-sure-deactivate-title')}
        modalDescription={t('usersettings.modal.are-you-sure-deactivate-description')}
        modalButton={t('usersettings.modal.are-you-sure-deactivate-button')}
        onHandleClick={() => handleClicked()}
        showCloseButton
        closeButton={t('usersettings.modal.are-you-sure-cancel-button') as string}
        onCloseModal={() => setShowModal(false)}
        actionButtonTheme={ButtonTheme.DANGER}
      />
    </div>
  );
};

export default DeactivateAccount;
