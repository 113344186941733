import * as React from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { useTranslation } from 'react-i18next';
import { RoleType } from './types';

const RoleMultiSelect = (fieldRenderProps: FieldRenderProps) => {
  const { t } = useTranslation();
  const roles: RoleType[] = [
    { id: 'Employee', name: t('backoffice.roles.Employee') },
    { id: 'TeamLead', name: t('backoffice.roles.TeamLead') },
    { id: 'HumanResources', name: t('backoffice.roles.HumanResources') },
    { id: 'Administrator', name: t('backoffice.roles.Administrator') },
  ];
  const { label, id, valid, disabled, wrapperStyle, value, onBlur, onChange, onFocus } = fieldRenderProps;

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id="category"
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className="k-form-field-wrap">
        <MultiSelect
          data={roles}
          value={value?.map((v: string) => roles.find((role) => role.id === v))}
          textField="name"
          dataItemKey="id"
          placeholder={t('backoffice.multi-select.please-select') || ''}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(selection) => {
            // eslint-disable-next-line no-param-reassign
            selection.value = selection.value.map((selectedValue: RoleType) => selectedValue.id);
            onChange(selection);
          }}
        />
      </div>
    </FieldWrapper>
  );
};

export default RoleMultiSelect;
