import SurveyPost from '../../../Models/Survey/SurveyPost';
import http from '../../../Utils/HttpClient';

const base = `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_SURVEYS}/Surveys`;

const postSurvey = async (surveyId: string, answers: SurveyPost) => {
  const { data } = await http.post(`${base}/${surveyId}/SubmitResponse`, answers);
  return { data };
};

export default postSurvey;
