import SurveyScore from "../../../../../Models/Dashboard/SurveyScore";
import SurveyText from "../../../../../Models/Survey/SurveyText";
import LanguageHelper from "../../../../../Utils/LanguageHelper";

export default function GetLabelName(statistics: SurveyScore[], key: string, index: number) {
  if (statistics) {
    const label: SurveyText[] = statistics![index].getDomainLabel(key);

    if (label[0] !== undefined) {
      return LanguageHelper.SelectLanguage(label);
    }
  }

  return '';
}
