import React, { useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import classNames from 'classnames';

import './NavigationBar.scss';
import { SelectType } from '../../Types/SelectType';
import useUser from '../../Hooks/useUser';
import Button from '../Button/Button';

type PropType = {
  hasBorder?: boolean;
  hasTitle?: boolean;
  showProfile?: boolean;
  menuTitle: string;
  totalQuestions?: number;
  currentQuestion?: number;
  showStartSurveyButton?: boolean;
  showMenu?: boolean;
  showLanguage?: boolean;
  showContentButtons?: boolean;
};

const NavigationBar = ({
  menuTitle,
  hasBorder = false,
  hasTitle = false,
  showProfile = false,
  currentQuestion = 0,
  totalQuestions = 0,
  showStartSurveyButton = false,
  showMenu = true,
  showLanguage = true,
  showContentButtons = false,
}: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { userInfo } = useUser();
  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);
  const { t, i18n } = useTranslation();

  const mobileMenuRef = useRef<any>();
  const desktopMenuRef = useRef<any>();

  const MENU_TRIGGER_CLASSNAME = 'menu__trigger';

  let menuItems = [
    { value: '/dashboard', label: 'menu.employee-dashboard' },
    { value: '/edit-team', label: 'menu.edit-team' },
    { value: '/edit-nickname', label: 'menu.change-name' },
    { value: '/edit-password', label: 'menu.change-password' },
    { value: '/deactivate-account', label: 'menu.deactivate-account' },
    { value: '/what-is-well-at-work', label: 'menu.what-is-well-at-work' },
    { value: '/our-mission', label: 'menu.our-mission' },
    { value: '/about-us', label: 'menu.about-us' },
    { value: 'logout', label: 'menu.logout' },
  ];

  if (client === 'tno') {
    menuItems = menuItems.filter((item) => item.value !== '/edit-team');
  }

  if (userInfo) {
    if (client === 'tno') {
      // CHECK-RIGHT teamlead
      if (userInfo.checkPermission('read:organisations:customer')) {
        menuItems.unshift({ value: '/hr-dashboard', label: 'menu.manager-dashboard' });
      }
    } else {
      const deactivateAccountIndex = menuItems.findIndex((item) => item.value === '/deactivate-account');
      menuItems.splice(deactivateAccountIndex, 1);

      // CHECK-RIGHT teamlead
      // eslint-disable-next-line no-lonely-if
      if (userInfo.checkPermission('write:organisations:customer')) {
        menuItems.unshift({ value: '/hr-dashboard', label: 'menu.manager-dashboard' });
      } else if (userInfo.checkPermission('read:organisations:customer')) {
        menuItems.unshift({ value: '/teamlead-dashboard', label: 'menu.teamlead-dashboard' });
      }
    }

    // CHECK-RIGHT HR
    if (userInfo.checkPermission('write:organisations:customer')) {
      menuItems.unshift({ value: `/backoffice/${userInfo.customerId}`, label: `menu.team-editor` });
    }

    // CHECK-RIGHT ADMIN
    if (userInfo.checkPermission('write:customers:all')) {
      menuItems.unshift({ value: '/customers', label: 'menu.customers' });
    }
  }

  const { logout } = useAuth0();

  function clearUserdata() {
    localStorage.removeItem('acceptedTerms');
    localStorage.removeItem('user');
  }

  const listItems = menuItems.map((item) => {
    if (item.value === 'logout') {
      return (
        <li key={item.value}>
          <button
            onClick={() => {
              clearUserdata();
              logout();
            }}
            type="button"
            aria-label={`${t(item.label)}`}
            className="menu-item"
          >
            {t(item.label)}
          </button>
        </li>
      );
    }
    return (
      <li key={item.value}>
        <a
          href={item.value}
          className="menu-item"
          title={`${t(item.label)}`}
        >
          {t(item.label)}
        </a>
      </li>
    );
  });

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const languages = ['nl', 'en'].map((lang): SelectType<string> => ({ value: lang, label: lang.toUpperCase() }));
  const selectedLanguage = languages.find((l) => l.value === i18n.language);

  const renderProfile = () => (
    <div className="profile">
      <button
        className={`profile__picture ${MENU_TRIGGER_CLASSNAME}`}
        onClick={handleClick}
        type="button"
        aria-label="Menu"
        ref={mobileMenuRef}
      />
      <button
        className={`profile__name ${MENU_TRIGGER_CLASSNAME}`}
        onClick={handleClick}
        aria-label="Menu"
        type="button"
        ref={desktopMenuRef}
      >
        <span className={`profile__name ${MENU_TRIGGER_CLASSNAME}`}>{userInfo?.nickname}</span>
        <div className={`profile__icon ${MENU_TRIGGER_CLASSNAME}`}>
          <svg
            id="hamburger"
            className={classNames('Header__toggle-svg', { active: isActive })}
            viewBox="0 0 60 40"
          >
            <g
              stroke="#000"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                id="top-line"
                d="M10,10 L50,10 Z"
              />
              <path
                id="middle-line"
                d="M10,20 L50,20 Z"
              />
              <path
                id="bottom-line"
                d="M10,30 L50,30 Z"
              />
            </g>
          </svg>
        </div>
      </button>

      <div className={classNames('profile__menu', { 'profile__menu--active': isActive })}>
        <ul>{listItems}</ul>
      </div>
    </div>
  );

  return (
    <>
      <nav>
        <div className={classNames('navigation', { 'navigation--has-border': hasBorder })}>
          <div className={classNames('navigation__title', { 'navigation__title--has-title': hasTitle })}>
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: menuTitle }} />
          </div>
          <div
            className={classNames('navigation__pagination', { 'navigation__pagination--has-pagination': !hasTitle })}
          >
            {currentQuestion !== 0 && (
              <p>{t('survey.question-counter', { current: currentQuestion, total: totalQuestions })}</p>
            )}
          </div>
          {currentQuestion === 0 && showContentButtons && (
            <div className={classNames('navigation__content')}>
              <Button
                onClick={() => navigate('/what-is-well-at-work')}
                label={t('menu.what-is-well-at-work')!}
              />
              <Button
                onClick={() => navigate('/our-mission')}
                label={t('menu.our-mission')!}
              />
              <Button
                onClick={() => navigate('/about-us')}
                label={t('menu.about-us')!}
              />
            </div>
          )}
          <div className="navigation__right">
            {showStartSurveyButton && (
              <a
                href="/survey/wellbeing"
                className="take-survey-button"
              >
                {t('menu.start-survey')}
              </a>
            )}
            {showLanguage && (
              <Select
                isSearchable={false}
                defaultValue={selectedLanguage}
                classNamePrefix="react-select"
                options={languages}
                onChange={(v): void => {
                  i18n.changeLanguage(v!.value);
                  document.getElementsByTagName('html')[0].setAttribute('lang', v!.value);
                }}
              />
            )}
            {showProfile && showMenu && renderProfile()}
          </div>
        </div>
      </nav>

      {showStartSurveyButton && (
        <div className="mobile-buttons">
          <div className="content-container">
            <a
              href="/survey/wellbeing"
              className="take-survey-button__mobile"
            >
              {t('menu.start-survey')}
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default NavigationBar;
