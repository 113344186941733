import React from 'react';
import { useTranslation } from 'react-i18next';
import UserInfo from '../../../../../Models/User/UserInfo';
import useCustomerById from '../../../Hooks/useCustomerById';

import './FeedbackButton.scss';

type PropType = {
  userInfo: UserInfo;
};

const FeedbackButton = ({ userInfo }: PropType) => {
  const { t } = useTranslation();
  const { data } = useCustomerById(userInfo.customerId);

  let href = `mailto:${window.__RUNTIME_CONFIG__.REACT_APP_FEEDBACK_EMAIL}`;

  if (data?.feedbackUrl) {
    href = data.feedbackUrl;
  }

  return (
    <a
      className="feedback-button"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {t('generic.feedback')}
    </a>
  );
};

export default FeedbackButton;
