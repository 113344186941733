import React from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@frontend/use-bem';
import { TeamCellType } from './types';

const TeamCell = ({ team }: TeamCellType) => {
  const { t } = useTranslation();
  const { bemClassName, bemClassNames } = useBem('default-cell');

  return (
    <td
      className={bemClassNames(['k-table-td', bemClassName()])}
      colSpan={1}
    >
      <span>{team || t('backoffice.multi-select.no-team')}</span>
    </td>
  );
};

export default TeamCell;
