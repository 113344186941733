import React from 'react';
import useBem from '@frontend/use-bem';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../../Shared/Components/DataGrid/components/Breadcrumbs/Breadcrumbs';
import Level from '../../../../../Shared/Enums/Level';
import { ManagePageHeaderType } from './types';

const ManagementPageHeader = ({ title, gridLayer }: ManagePageHeaderType) => {
  const { bemClassName } = useBem('manage-page-header');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;

  return (
    <div className={bemClassName()}>
      <Breadcrumbs title={title} />

      <div className="backoffice">
        <div className="backoffice-title">
          <h2>{title}</h2>
          <p>{t(`management.subtitle-description`)}</p>
        </div>
        <div className="backoffice-buttons">
          {gridLayer !== Level.ADMIN && client !== 'tno' && (
            <button
              onClick={() => navigate(`/upload-users/${customerId}`)}
              className="upload-button"
              type="button"
            >
              {t(`management.upload-with-file`)}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagementPageHeader;
