import React from 'react';

import { useTranslation } from 'react-i18next';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import EditPassword from '../Components/EditPassword';

const EditPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />

      <EditPassword />
    </>
  );
};

export default EditPasswordPage;
