import * as React from 'react';
import { useTranslation } from 'react-i18next';

import './Footer.scss';

const Footer = () => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="content-container">
        <div className="footer__text">
          <p>{t('generic.powered-by')}</p>
        </div>
        <div className="footer__logos">
          {client !== 'tno' && (
            <>
              <div className="logo">
                <img
                  className="deloitte"
                  src="/images/logos/deloitte.svg"
                  alt="Deloitte logo"
                />
              </div>
              <div className="logo">
                <img
                  className="zilveren-kruis"
                  src="/images/logos/zilverenKruis.svg"
                  alt="Zilveren Kruis logo"
                />
              </div>
              <div className="logo">
                <img
                  className="tno"
                  src="/images/logos/tno.svg"
                  alt="TNO logo"
                />
              </div>
            </>
          )}
          {client === 'tno' && (
            <>
              <div className="logo">
                <img
                  className="deloitte"
                  src="/images/logos/deloitte.svg"
                  alt="Deloitte logo"
                />
              </div>
              <div className="logo">
                <img
                  className="achmea"
                  src="/images/logos/achmea.svg"
                  alt="Achmea logo"
                />
              </div>
              <div className="logo">
                <img
                  className="dsm"
                  src="/images/logos/dsm.svg"
                  alt="DSM logo"
                />
              </div>
              <div className="logo">
                <img
                  className="trimbos"
                  src="/images/logos/trimbos.png"
                  alt="Trimbos logo"
                />
              </div>
              <div className="logo">
                <img
                  className="tno"
                  src="/images/logos/tno.svg"
                  alt="TNO logo"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
