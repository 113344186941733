import React, { useContext, ReactNode, useState, useMemo } from 'react';
import SurveyAnswer from '../Models/Survey/SurveyAnswer';
import SurveyInformation from '../Models/Survey/SurveyInformation';

export const SurveyFilledInData = React.createContext({
  questionAnswers: [] as SurveyAnswer[],
  surveyInformation: {} as SurveyInformation,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setQuestionAnswers: (questionAnswer: SurveyAnswer | undefined) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSurveyInformation: (surveyInformation: SurveyInformation) => {},
});

export const useFilledInSurveyData = () => useContext(SurveyFilledInData);

type PropType = {
  children: ReactNode[] | ReactNode;
};

export const SurveyFilledInDataProvider = (props: PropType) => {
  const [questionAnswers, updateQuestionAnswers] = useState<SurveyAnswer[]>([]);
  const [surveyInformation, updateSurveyInformation] = useState<SurveyInformation>({ surveyId: '', totalQuestions: 0 });

  const setQuestionAnswers = (questionAnswer: SurveyAnswer | undefined) => {
    if (questionAnswer) {
      const objIndex = questionAnswers.findIndex((obj) => obj.questionKey === questionAnswer.questionKey);

      if (objIndex !== -1) {
        if (questionAnswer.optionKey) questionAnswers[objIndex].optionKey = questionAnswer.optionKey;
        if (questionAnswer.textValue) questionAnswers[objIndex].textValue = questionAnswer.textValue;
        if (questionAnswer.numericValue) questionAnswers[objIndex].numericValue = questionAnswer.numericValue;
        if (questionAnswer.questionKey) questionAnswers[objIndex].questionKey = questionAnswer.questionKey;
        if (questionAnswer.questionLabel) questionAnswers[objIndex].questionLabel = questionAnswer.questionLabel;

        updateQuestionAnswers(questionAnswers);
      } else {
        updateQuestionAnswers([...questionAnswers, questionAnswer]);
      }
    } else {
      updateQuestionAnswers([]);
    }
  };

  const setSurveyInformation = (information: SurveyInformation) => {
    updateSurveyInformation(information);
  };

  const { children } = props;
  const context = useMemo(
    () => ({ questionAnswers, setQuestionAnswers, surveyInformation, setSurveyInformation }),
    [questionAnswers, setQuestionAnswers, surveyInformation, setSurveyInformation],
  );

  return <SurveyFilledInData.Provider value={context}>{children}</SurveyFilledInData.Provider>;
};
