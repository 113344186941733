import Survey from '../../../Models/Survey/Survey';
import SurveySpecification from '../../../Models/Survey/SurveySpecification';
import http from '../../../Utils/HttpClient';

const base = `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_SURVEYS}/Surveys`;

const getSurvey = async (surveyId: string) => {
  const { data } = await http.get(`${base}/${surveyId}`);
  return new Survey(
    data.Id,
    data.Active,
    data.CreatedDate,
    data.CustomerId,
    data.Description,
    data.EndDate,
    data.Responses,
    SurveySpecification.fromJson(data.SerializedSpecification),
    data.StartDate,
    data.Title,
  );
};

export default getSurvey;
