import React from 'react';
import { useTranslation } from 'react-i18next';
import SurveyAnswer from '../../../../Models/Survey/SurveyAnswer';
import SurveyChoiceQuestion from '../../../../Models/Survey/SurveyChoiceQuestion';
import SurveyQuestion from '../../../../Models/Survey/SurveyQuestion';
import SurveyQuestionType from '../../../../Models/Survey/SurveyQuestionType';
import SurveySliderQuestion from '../../../../Models/Survey/SurveySliderQuestion';
import { useFilledInSurveyData } from '../../../../Store/SurveyContext';
import LanguageHelper from '../../../../Utils/LanguageHelper';

import MultipleChoiceQuestion from '../SurveyMultipleChoice/MultipleChoiceQuestion';
import SurveyNavigation from '../SurveyNavigation/SurveyNavigation';
import SliderQuestion from '../SurveySlider/SliderQuestion';
import OpenQuestion from '../OpenQuestion/OpenQuestion';
import Survey from '../../../../Models/Survey/Survey';

type PropType = {
  survey: Survey;
  surveyId: string;
  questionNumber: number;
  totalQuestions: number;
  onHandleNext: () => void;
  onHandlePrevious: () => void;
};

const SurveyQuestions = ({
  survey,
  surveyId,
  questionNumber,
  onHandleNext,
  onHandlePrevious,
  totalQuestions,
}: PropType) => {
  const { t } = useTranslation();

  const { questionAnswers, setQuestionAnswers, setSurveyInformation } = useFilledInSurveyData();

  const allQuestions = survey!.getAllQuestions();

  function checkDisabledButton(number: number) {
    if (questionNumber !== 0) {
      const objIndex = questionAnswers.findIndex((obj) => obj.questionKey === allQuestions[number - 1].key);

      return objIndex === -1;
    }
    return false;
  }

  const handleAnswer = (answer: SurveyAnswer) => {
    setQuestionAnswers(answer);
  };

  const handleNext = () => {
    setSurveyInformation({ surveyId, totalQuestions: survey!.getQuestionCount() });
    onHandleNext();
  };

  const renderQuestion = (number: number) => {
    if (number < 1) return undefined;

    const question: SurveyQuestion = allQuestions[number - 1];
    const { key, type, labelText } = question;

    const objIndex = questionAnswers.findIndex((obj) => obj.questionKey === key);
    const previousAnswer = objIndex !== -1 ? questionAnswers[objIndex] : null;

    if (type === SurveyQuestionType.OPEN) {
      return (
        <div className="survey-section">
          <OpenQuestion
            key={key}
            surveyId={surveyId}
            questionKey={key}
            question={LanguageHelper.SelectLanguage(labelText)}
            onAnswerSelect={(answer: SurveyAnswer) => handleAnswer(answer)}
            previousAnswer={(previousAnswer?.textValue as string) ?? undefined}
          />
        </div>
      );
    }

    if (type === SurveyQuestionType.SLIDER) {
      const { maxLabelText, maxValue, minLabelText, minValue, smallStepValue } = question as SurveySliderQuestion;

      return (
        <div className="survey-section">
          <SliderQuestion
            key={key}
            surveyId={surveyId}
            questionKey={key}
            minLabel={LanguageHelper.SelectLanguage(minLabelText)}
            maxLabel={LanguageHelper.SelectLanguage(maxLabelText)}
            minValue={minValue}
            maxValue={maxValue}
            smallStepValue={smallStepValue}
            question={LanguageHelper.SelectLanguage(labelText)}
            onAnswerSelect={(answer: SurveyAnswer) => handleAnswer(answer)}
            previousAnswer={(previousAnswer?.numericValue as number) ?? undefined}
          />
        </div>
      );
    }

    const { options } = question as SurveyChoiceQuestion;

    return (
      <div className="survey-section">
        <MultipleChoiceQuestion
          key={key}
          surveyId={surveyId}
          questionKey={key}
          onAnswerSelect={(answer: SurveyAnswer) => handleAnswer(answer)}
          question={LanguageHelper.SelectLanguage(labelText)}
          options={options}
          previousAnswer={previousAnswer?.optionKey ?? undefined}
        />
      </div>
    );
  };

  return (
    <>
      {renderQuestion(questionNumber)}

      <SurveyNavigation
        onHandlePrevious={onHandlePrevious}
        onHandleNext={() => handleNext()}
        firstQuestion={questionNumber === 0}
        lastQuestion={questionNumber === totalQuestions}
        labelNext={t('survey.next')}
        nextDisabled={checkDisabledButton(questionNumber)!}
        labelPrev={t('survey.back')}
      />
    </>
  );
};

export default SurveyQuestions;
