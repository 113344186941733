import React from 'react';
import { useTranslation } from 'react-i18next';
import IStatistic from '../../../../../Models/Interfaces/IStatistic';

import StatBlockEmployer from '../../../../../Shared/Components/StatBlock/StatBlockEmployer';
import TextBlockBig from '../../../../../Shared/Components/StatBlock/TextBlockBig';
import StatBlockTheme from '../../../../../Shared/Enums/StatBlockTheme';

type PropType = {
  dataPresent: boolean;
  isLoading: boolean;
  filterOneLowest: IStatistic;
  filterOneLowestTitle: string;
  filterOneHighest: IStatistic;
  filterOneHighestTitle: string;
  filterTwoLowest: IStatistic;
  filterTwoLowestTitle: string;
  filterTwoHighest: IStatistic;
  filterTwoHighestTitle: string;
};

const StatisticsRight = (props: PropType) => {
  const { t } = useTranslation();
  const {
    dataPresent,
    isLoading,
    filterOneLowest,
    filterOneLowestTitle,
    filterOneHighest,
    filterOneHighestTitle,
    filterTwoLowest,
    filterTwoLowestTitle,
    filterTwoHighest,
    filterTwoHighestTitle,
  } = props;

  return (
    <div className="statistics__right">
      <div className="statistic__row statistic__row--small">
        <StatBlockEmployer
          measurement="/10"
          highestScore={Math.round(filterOneHighest.value)}
          highestCategory={filterOneHighestTitle}
          lowestScore={Math.round(filterOneLowest.value)}
          lowestCategory={filterOneLowestTitle}
          theme={StatBlockTheme.BLUE}
          isLoading={isLoading}
        />
      </div>
      {filterOneHighest.value !== 0 && filterTwoHighest.value !== 0 && filterTwoLowest.value !== 0 && (
        <div className="statistic__row statistic__row--small">
          <StatBlockEmployer
            measurement="/10"
            highestScore={Math.round(filterTwoHighest.value)}
            highestCategory={filterTwoHighestTitle}
            lowestScore={Math.round(filterTwoLowest.value)}
            lowestCategory={filterTwoLowestTitle}
            theme={StatBlockTheme.GREEN}
            isLoading={isLoading}
          />
        </div>
      )}
      <div className="statistic__row">
        <TextBlockBig
          dataPresent={dataPresent}
          title={t('dashboard.manager.cards.big-card.title')}
          subtitle={t('dashboard.manager.cards.big-card.subtitle')}
          description={t('dashboard.manager.cards.big-card.description')}
          theme={StatBlockTheme.YELLOW}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default StatisticsRight;
