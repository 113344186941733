import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellProps } from '@progress/kendo-react-grid';
import { UserDataType } from '../../types';
import Level from '../../../../Enums/Level';
import useUser from '../../../../Hooks/useUser';

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: UserDataType) => void;
  destroyItem: (item: UserDataType) => void;
  gridLayer?: Level;
  generateLink?: (dataItem: any) => void;
  removePermission?: string;
}

const EditCell = ({
  enterEdit,
  dataItem,
  destroyItem,
  gridLayer = undefined,
  generateLink = undefined,
  removePermission = undefined,
}: EditCommandCellProps) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { userInfo } = useUser();
  const { t } = useTranslation();

  return (
    <td className="actions">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
        onClick={() => enterEdit(dataItem)}
        type="button"
      >
        <span className="k-icon k-i-edit" />
        {t(`management.edit`)}
      </button>
      {userInfo && userInfo.checkPermission(removePermission || '') && dataItem.EmailAddress === undefined && (
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => destroyItem(dataItem)}
          type="button"
        >
          <span className="k-icon k-i-delete" />
          {t(`management.delete`)}
        </button>
      )}
      {gridLayer !== Level.ADMIN && gridLayer !== Level.USERS && client === 'tno' && (
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => (generateLink ? generateLink(dataItem) : null)}
          type="button"
        >
          {t(`management.generate-link.button`)}
        </button>
      )}
    </td>
  );
};

export default EditCell;
