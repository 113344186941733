import React from 'react';
import { useTranslation } from 'react-i18next';
import IStatistic from '../../../../../Models/Interfaces/IStatistic';

import StatBlock from '../../../../../Shared/Components/StatBlock/StatBlock';
import TextBlockBig from '../../../../../Shared/Components/StatBlock/TextBlockBig';
import StatBlockTheme from '../../../../../Shared/Enums/StatBlockTheme';

type PropType = {
  dataPresent: boolean;
  isLoading: boolean;
  lowest: IStatistic;
  lowestTitle: string | null;
  highest: IStatistic;
  highestTitle: string | null;
  showScores?: boolean;
};

const StatisticsRight = ({
  dataPresent,
  isLoading,
  lowest,
  lowestTitle,
  highest,
  highestTitle,
  showScores = true,
}: PropType) => {
  const { t } = useTranslation();

  return (
    <div className="statistics__right">
      <div className="statistic__row statistic__row--small">
        <StatBlock
          description={`${t('dashboard.employee.cards.highest')} ${t('dashboard.manager.cards.category')}`}
          measurement="/10"
          score={Math.round(highest.value)}
          category={highestTitle === null ? '' : highestTitle}
          theme={StatBlockTheme.BLUE}
          isLoading={isLoading}
          showScore={showScores}
        />
        <StatBlock
          description={`${t('dashboard.employee.cards.lowest')} ${t('dashboard.manager.cards.category')}`}
          measurement="/10"
          score={Math.round(lowest.value)}
          category={lowestTitle === null ? '' : lowestTitle}
          theme={StatBlockTheme.ORANGE}
          isLoading={isLoading}
          showScore={showScores}
        />
      </div>
      <div className="statistic__row">
        <TextBlockBig
          title={t('dashboard.employee.cards.big-card.title')}
          subtitle={t('dashboard.employee.cards.big-card.subtitle')}
          description={t('dashboard.employee.cards.big-card.description')}
          theme={StatBlockTheme.GREEN}
          isLoading={isLoading}
          dataPresent={dataPresent}
        />
      </div>
    </div>
  );
};

export default StatisticsRight;
