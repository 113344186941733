import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <button
      type="button"
      onClick={() => navigate(-1)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.558"
        height="10.372"
        viewBox="0 0 15.558 10.372"
      >
        <path
          id="Icon_material-keyboard-backspace"
          data-name="Icon material-keyboard-backspace"
          d="M20.058,13.322H7.81l3.094-3.1L9.686,9,4.5,14.186l5.186,5.186L10.9,18.153,7.81,15.05H20.058Z"
          transform="translate(-4.5 -9)"
        />
      </svg>

      {t('generic.back')}
    </button>
  );
};

export default BackButton;
