import { useQuery, UseQueryResult } from '@tanstack/react-query';
import Survey from '../../../Models/Survey/Survey';
import getSurvey from '../Data/SurveyService';

const useSurvey = (surveyId: string): UseQueryResult<Survey, Error> =>
  useQuery(['survey', surveyId], () => getSurvey(surveyId), {
    staleTime: undefined,
  });

export default useSurvey;
