import React from 'react';
import classNames from 'classnames';

import StatBlockTheme from '../../Enums/StatBlockTheme';

import './StatBlock.scss';
import Loader from '../Loader/Loader';

type PropType = {
  description: string;
  measurement: string;
  score: number;
  category: string;
  theme: StatBlockTheme;
  isLoading: boolean;
  showScore?: boolean;
};

const StatBlock = ({ description, measurement, score, theme, isLoading, category, showScore = true }: PropType) => (
  <div className={classNames('stat-block', `stat-block--${theme}`, { 'stat-block--no-score': !showScore })}>
    {!isLoading && score !== 0 && (
      <>
        {showScore && (
          <div className="stat-block__score">
            <div className="stat-block__score--large">{score}</div>
            <div className="stat-block__score--small">{measurement}</div>
          </div>
        )}
        <div className="stat-block__description">
          <span>
            {description} <b>{category}</b>
          </span>
        </div>
      </>
    )}
    {isLoading && (
      <div className="loader-position">
        <Loader white />
      </div>
    )}
  </div>
);

export default StatBlock;
