import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FilterBarEmployer from './FilterBar/FilterBarEmployer';
import StatisticsRightEmployer from './StatisticsRight/StatisticsRightEmployer';
import ChartExplanation from './ChartExplanation/ChartExplanation';
import DomainOverview from '../DomainOverview/DomainOverview';

import { useFilledInFilterData } from '../../../../Store/DashboardContext';
import useStatisticsEmployer from '../../Hooks/useStatisticsEmployer';
import FeedbackButton from './FeedbackButton/FeedbackButton';
import GetLabelName from './Functions/LabelName';
import RadarChart from '../../../../Shared/Components/RadarChart/RadarChart';
import Loader from '../../../../Shared/Components/Loader/Loader';
import usePeriod from '../../Hooks/usePeriod';
import UserInfo from '../../../../Models/User/UserInfo';
import FeedbackButtonUrl from './FeedbackButton/FeedbackButtonUrl';
import FilterData from '../../../../Models/Dashboard/FilterData';
import useOrganisations from '../../Hooks/useOrganisations';
import useDivisions from '../../Hooks/useDivisions';
import useBusinessUnits from '../../Hooks/useBusinessUnits';
import useUnits from '../../Hooks/useUnits';
import useTeams from '../../Hooks/useTeams';

import './DashboardOverview.scss';

type DashboardType = {
  userInfo: UserInfo;
};

const DashboardOverviewEmployer = ({ userInfo }: DashboardType) => {
  const surveyId = window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID;
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;

  const { t } = useTranslation();
  const { filterData } = useFilledInFilterData();
  const { isLoading: organisationLoading, data: organisations } = useOrganisations();
  const { isLoading: divisionLoading, data: divisions } = useDivisions();
  const { isLoading: businessUnitLoading, data: businessUnits } = useBusinessUnits();
  const { isLoading: unitLoading, data: units } = useUnits();
  const { isLoading: teamLoading, data: teams } = useTeams();
  const { isLoading: periodLoading, data: periodData } = usePeriod(surveyId, {});
  const { isLoading, data: statistics } = useStatisticsEmployer(filterData?.filterDataQuery);
  const [showDetailedOverview, setShowDetailedOverview] = useState(true);

  const handleToggleDomainStats = () => {
    setShowDetailedOverview(!showDetailedOverview);
  };

  const getLegendName = (filterDataItem: FilterData) => {
    const { team, unit, businessUnit, division, organisation } = filterDataItem;

    if (team.value !== null) {
      return team.label;
    }

    if (unit.value !== null) {
      return unit.label;
    }

    if (businessUnit.value !== null) {
      return businessUnit.label;
    }

    if (division.value !== null) {
      return division.label;
    }

    if (organisation.value !== null) {
      return organisation.label;
    }

    return t('dashboard.filters.all');
  };

  const legendNameOne = getLegendName(filterData.scopeFilter[0]);
  const legendNameTwo = getLegendName(filterData.scopeFilter[1]);

  if (organisationLoading || divisionLoading || businessUnitLoading || unitLoading || teamLoading || periodLoading) {
    return <Loader />;
  }

  return (
    <>
      <FilterBarEmployer
        introTitle={t('dashboard.manager.subtitle')}
        organisations={organisations || []}
        divisions={divisions || []}
        businessUnits={businessUnits || []}
        units={units || []}
        teams={teams || []}
        periodData={periodData || { global: [], personal: [] }}
        isLoading={isLoading || organisationLoading || unitLoading || teamLoading}
      />
      <div className="content-container">
        <div className="overview__statistics">
          <RadarChart
            statistics={statistics}
            legendLabelOne={legendNameOne}
            legendLabelTwo={legendNameTwo}
            isLoading={isLoading || organisationLoading || unitLoading || teamLoading}
            isHR
            filterOneParticipants={statistics ? statistics[0].responseCount : 0}
            filterOneUserCount={statistics ? statistics[0].userCount : 0}
            filterTwoParticipants={
              statistics !== null && statistics !== undefined && statistics?.length > 1
                ? statistics[1].responseCount
                : 0
            }
            filterTwoUserCount={
              statistics !== null && statistics !== undefined && statistics?.length > 1 ? statistics[1].userCount : 0
            }
          />
          {statistics && statistics[0]?.domains.length > 0 && (
            <StatisticsRightEmployer
              dataPresent={statistics[0]?.domains.length > 0}
              filterOneLowest={statistics ? statistics![0].lowest : { key: '', value: 0 }}
              filterOneLowestTitle={statistics ? GetLabelName(statistics, statistics![0].lowest.key, 0) : ''}
              filterOneHighest={statistics ? statistics![0].highest : { key: '', value: 0 }}
              filterOneHighestTitle={statistics ? GetLabelName(statistics, statistics![0].highest.key, 0) : ''}
              filterTwoLowest={statistics?.length > 1 ? statistics![1].lowest : { key: '', value: 0 }}
              filterTwoLowestTitle={
                statistics?.length > 1 ? GetLabelName(statistics, statistics![1].lowest.key, 1) : ''
              }
              filterTwoHighest={statistics?.length > 1 ? statistics![1].highest : { key: '', value: 0 }}
              filterTwoHighestTitle={
                statistics?.length > 1 ? GetLabelName(statistics, statistics![1].highest.key, 1) : ''
              }
              isLoading={isLoading || organisationLoading || unitLoading || teamLoading}
            />
          )}
        </div>

        <ChartExplanation
          title={t('dashboard.domains.title')}
          text={t('dashboard.domains.description')}
          domainScoreExplanation={t('dashboard.domains.DomainScoreExplanation')}
          legendLabelOne={legendNameOne}
          legendLabelTwo={legendNameTwo}
          onHandleToggleDomainStats={() => handleToggleDomainStats()}
          statsActive={showDetailedOverview}
        />
        <DomainOverview
          showStats={showDetailedOverview}
          statistics={statistics!}
          isLoading={isLoading || organisationLoading || unitLoading || teamLoading}
        />
      </div>
      {client !== 'TNO' && userInfo?.customerId && <FeedbackButtonUrl userInfo={userInfo} />}
      {client === 'TNO' && userInfo?.customerId && <FeedbackButton />}
    </>
  );
};

export default DashboardOverviewEmployer;
