import React from 'react';
import classNames from 'classnames';

import './Button.scss';
import ButtonTheme from '../../Enums/ButtonTheme';

type PropType = {
  disabled?: boolean;
  isSubmit?: boolean;
  label?: string;
  onClick: () => void;
  theme?: ButtonTheme;
};

const Button = ({ disabled = false, isSubmit = false, onClick, theme = ButtonTheme.NORMAL, label = '' }: PropType) => (
  <button
    type={isSubmit ? 'submit' : 'button'}
    className={classNames(['button', `button--${theme}`], { 'button--disabled': disabled })}
    title={label || undefined}
    onClick={(e: any): void => {
      e.preventDefault();
      onClick();
    }}
    disabled={disabled}
  >
    {label}
  </button>
);

export default Button;
