import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridRowProps,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { useParams } from 'react-router-dom';
import { CompositeFilterDescriptor, DataResult, State } from '@progress/kendo-data-query';
import { UserDataGridType, UserDataType } from './types';
import { activateUser, deactivateUser, updateUser } from '../../Data/GetUserService';
import { addUser, deleteUser } from '../../../Features/Management/Data/UserManagerService';
import useUser from '../../Hooks/useUser';
import DataLoader from './components/DataLoader';
import EditUserForm from './components/EditUserForm/EditUserForm';
import Level from '../../Enums/Level';
import getFilterLevel from '../../../Features/Management/Pages/functions/getFilterLevel';
import RoleCell from './components/DataGridCells/RoleCell';
import TeamCell from './components/DataGridCells/TeamCell';
import useTeams from '../../../Features/Dashboard/Hooks/useTeams';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import EditCell from './components/DataGridCells/EditCell';

import './DataGrid.scss';
import ErrorMessages from '../ErrorMessage/ErrorMessages';

const SelectedTeamCell = ({
  gridCellProps,
  teams,
}: {
  gridCellProps: GridCellProps;
  teams: FilterOption[] | undefined;
}) => {
  const { TeamId } = gridCellProps.dataItem;

  const teamName = teams?.find((team) => team.id === TeamId)?.name;
  return <TeamCell team={teamName} />;
};

const UserDataGrid = ({ level }: UserDataGridType) => {
  const { organisationId, divisionId, businessUnitId, unitId } = useParams();
  const { userInfo } = useUser();
  const { data: teams } = useTeams();
  const { t } = useTranslation();
  const [catchedErrors, setCatchedErrors] = useState<string[]>([]);

  const [users, setUsers] = React.useState<DataResult>({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = React.useState<State>({
    take: 25,
    skip: 0,
    filter: getFilterLevel({ gridLayer: level }) as CompositeFilterDescriptor,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataResult: DataResult) => {
    setUsers(dataResult);
  };

  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<UserDataType>();

  const setUserLayers = (user: UserDataType) => {
    if (!user.TeamId) {
      // eslint-disable-next-line no-param-reassign
      user.OrganisationId = organisationId || null;
      // eslint-disable-next-line no-param-reassign
      user.DivisionId = divisionId || null;
      // eslint-disable-next-line no-param-reassign
      user.BusinessUnitId = businessUnitId || null;
      // eslint-disable-next-line no-param-reassign
      user.UnitId = unitId || null;
      // eslint-disable-next-line no-param-reassign
      user.TeamId = null;
    } else {
      // eslint-disable-next-line no-param-reassign
      user.OrganisationId = null;
      // eslint-disable-next-line no-param-reassign
      user.DivisionId = null;
      // eslint-disable-next-line no-param-reassign
      user.BusinessUnitId = null;
      // eslint-disable-next-line no-param-reassign
      user.UnitId = null;
    }

    return user;
  };

  const enterEdit = (item: UserDataType) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const saveAction = async (user: UserDataType) => {
    // eslint-disable-next-line no-param-reassign
    user = setUserLayers(user);

    addUser(user)
      .catch((error) => {
        if (error.status !== 204 || error.status !== 200) {
          const title = error?.data?.title;

          if (title === 'Unique constraint violated') {
            setCatchedErrors([...catchedErrors, t('backoffice.errors.duplicate-email')]);
          }

          if (error.status === 422) {
            setCatchedErrors([...catchedErrors, error.data.title]);
          } else {
            setCatchedErrors([...catchedErrors, t('backoffice.errors.unknown-error')]);
          }
        }
      })
      .then((result) => {
        if (result?.data !== undefined) {
          // eslint-disable-next-line no-param-reassign
          delete result.data['@odata.context'];
          users.data.unshift(result.data);

          const newUsers = { ...users };

          setUsers(newUsers);
          setOpenForm(false);
        }
      });
  };

  const updateAction = async (user: UserDataType) => {
    if (user.Id) {
      const selectedUser: UserDataType = users.data.find((item: UserDataType) => item.Id === user.Id);

      if (selectedUser && selectedUser.IsActive !== user.IsActive) {
        if (user.IsActive) {
          activateUser(user.Id);
        } else {
          deactivateUser(user.Id);
        }
      }

      // eslint-disable-next-line no-param-reassign
      user = setUserLayers(user);

      updateUser(user.Id as string, user)
        .catch((error) => {
          if (error.status !== 204 || error.status !== 200) {
            const title = error?.data?.title;

            if (error.status === 422) {
              setCatchedErrors([...catchedErrors, error.data.title]);
            } else if (title === 'Unique constraint violated') {
              setCatchedErrors([t('backoffice.errors.duplicate-email')]);
            } else {
              setCatchedErrors([...catchedErrors, t('backoffice.errors.unknown-error')]);
            }
          }
        })
        .then((response) => {
          if (response === '') {
            users.data = users.data.map((item) => {
              if (user.Id === item.Id) {
                // eslint-disable-next-line no-param-reassign
                item = { ...user };
              }
              return item;
            });

            setUsers({ ...users });
            setOpenForm(false);
          }
        });
    }
  };

  const handleSubmit = async (user: UserDataType) => {
    if (!user.Id) {
      await saveAction(user);
    } else {
      await updateAction(user);
    }
  };

  const addNew = () => {
    setEditItem({} as UserDataType);
    setOpenForm(true);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const destroyItem = async (user: UserDataType) => {
    if (user.Id) {
      const result = await deleteUser(user.Id);

      if (result.status === 204) {
        const index = users.data.findIndex((item: UserDataType) => item.Id === user.Id);
        users.data = users.data.splice(index, 1);
        setUsers({ ...users });
      }
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const UserEditCell = (props: GridCellProps) => (
    <EditCell
      {...props}
      enterEdit={enterEdit}
      destroyItem={destroyItem}
    />
  );

  const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    const {
      // eslint-disable-next-line react/prop-types
      dataItem: { LastRunSuccessfull },
    } = props;
    const trProps: any = { className: LastRunSuccessfull ? '' : 'error-record' };
    // @ts-ignore
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <>
      <Grid
        filterable
        sortable
        pageable={{
          buttonCount: 8,
          pageSizes: [25, 50, 100],
        }}
        {...dataState}
        data={users}
        onDataStateChange={dataStateChange}
        rowRender={rowRender}
      >
        <GridToolbar className="grid-toolbar">
          {userInfo && userInfo.checkPermission('write:users:user') && (
            <button
              title={t(`management.users.add-new`)!}
              className="k-grid-button"
              onClick={addNew}
              type="button"
              aria-label={t(`management.users.add-new`)!}
            >
              {t(`management.users.add-new`)!}
            </button>
          )}
        </GridToolbar>
        <GridColumn
          field="EmailAddress"
          title={t(`management.users.title`) || ''}
        />
        <GridColumn
          field="TeamId"
          title={t(`management.users.team`) || ''}
          filterable={false}
          cell={(gridCellProps) => SelectedTeamCell({ gridCellProps, teams })}
        />
        {userInfo && userInfo.checkPermission('write:organisations:customer') && (
          <GridColumn
            field="Roles"
            title={t(`management.users.role`) || ''}
            filterable={false}
            sortable={false}
            cell={RoleCell}
          />
        )}
        <GridColumn
          title={t(`management.actions`) || ''}
          filterable={false}
          sortable={false}
          width={270}
          cell={UserEditCell}
        />
      </Grid>
      <DataLoader
        gridLayer={Level.USERS}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
      {openForm && (
        <EditUserForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem!}
        />
      )}
      <ErrorMessages
        errors={catchedErrors}
        onCloseError={(index) => setCatchedErrors(catchedErrors.filter((_, i) => i !== index) as string[])}
      />
    </>
  );
};

export default UserDataGrid;
