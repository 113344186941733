import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardContent from '../../../../../Models/Dashboard/DashboardContent';

import { useFilledInFilterData } from '../../../../../Store/DashboardContext';
import FilterBarSelectButton from './FilterBarSelectButtons/FilterBarSelectButton';
import FilterLevel from '../../../../../Shared/Enums/FilterLevel';
import useUser from '../../../../../Shared/Hooks/useUser';

import PeriodFilter from '../PeriodFilter/PeriodFilter';
import PeriodOptions from '../../../../../Models/Dashboard/PeriodOptions';
import PeriodFilterOption from '../../../../../Models/Dashboard/PeriodFilterOption';
import getPeriods from '../../../Data/PeriodService';

type PropType = {
  periodData: PeriodOptions;
  introTitle: string;
  introParagraph: string;
  isLoading: boolean;
};

const FilterBar = ({ periodData, introTitle, introParagraph, isLoading }: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const surveyId = window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID;

  const { t } = useTranslation();
  const { global, personal } = periodData;

  const { userInfo } = useUser();
  const { filterData, setFilterData, resetFilterData } = useFilledInFilterData();
  const [periodFilterOptions, setPeriodFilterOptions] = useState<PeriodFilterOption[]>(
    filterData.filterDataQuery.Filter2.Level === FilterLevel.SELF ? personal : global,
  );

  function getCompareData(filterLevel: FilterLevel) {
    const newFilterData: DashboardContent = filterData;

    const filterPath = newFilterData.scopeFilter[1];
    let periodFilterId: string = userInfo!.userId;

    // reset scope filter
    filterPath.organisation = {
      value: null,
      label: t('dashboard.filters.organisation'),
    };
    filterPath.division = {
      value: null,
      label: t('dashboard.filters.division'),
    };
    filterPath.businessUnit = {
      value: null,
      label: t('dashboard.filters.businessunit'),
    };
    filterPath.unit = {
      value: null,
      label: t('dashboard.filters.unit'),
    };
    filterPath.team = {
      value: null,
      label: t('dashboard.filters.team'),
    };
    filterPath.self = {
      value: null,
      label: t('dashboard.filters.self'),
    };

    switch (filterLevel) {
      case FilterLevel.ORGANISATION:
        filterPath.organisation = {
          value: userInfo!.organisationId,
          label: t('dashboard.filters.organisation'),
        };
        break;
      case FilterLevel.DIVISION:
        filterPath.division = {
          value: userInfo!.divisionId!,
          label: t('dashboard.filters.division'),
        };
        periodFilterId = userInfo!.businessUnitId!;
        break;
      case FilterLevel.BUSINESS_UNIT:
        filterPath.businessUnit = {
          value: userInfo!.businessUnitId!,
          label: t('dashboard.filters.businessunit'),
        };
        periodFilterId = userInfo!.businessUnitId!;
        break;
      case FilterLevel.UNIT:
        filterPath.unit = {
          value: userInfo!.unitId!,
          label: t('dashboard.filters.unit'),
        };
        periodFilterId = userInfo!.unitId!;
        break;
      case FilterLevel.TEAM:
        filterPath.team = {
          value: userInfo!.teamId!,
          label: t('dashboard.filters.team'),
        };
        periodFilterId = userInfo!.teamId!;
        break;
      case FilterLevel.SELF:
      default:
        filterPath.self = {
          value: userInfo!.userId,
          label: t('dashboard.filters.self'),
        };
    }

    getPeriods(
      surveyId,
      filterLevel !== FilterLevel.ORGANISATION
        ? {
            Level: filterLevel,
            Id: periodFilterId,
          }
        : {},
    ).then((periods) => {
      const usePersonalPeriods = filterData.filterDataQuery.Filter2.Level === FilterLevel.SELF;
      setPeriodFilterOptions(usePersonalPeriods ? periods.personal : periods.global);

      newFilterData.filterDataQuery.Filter2.DateFilters = [];
    });

    setFilterData(newFilterData);
  }

  function changeFilterPeriod(selectedPeriods: PeriodFilterOption[]) {
    const newFilterData: DashboardContent = filterData;

    newFilterData.filterDataQuery.Filter1.DateFilters = [personal[personal.length - 1]];
    newFilterData.filterDataQuery.Filter2.DateFilters = selectedPeriods;

    setFilterData(newFilterData);
  }

  return (
    <div className="content-container">
      <div className="overview__intro">
        <div className="paragraph">
          <h3>{introTitle}</h3>
          <p>{introParagraph}</p>
        </div>
        {!isLoading && (
          <div className="filter-row">
            <div className="filter-column">
              <div className="column-row column-row__right">
                <span>
                  <b>{t('dashboard.filters.compare-your-statistics-to')}</b>
                </span>

                <div className="scope-selection">
                  <FilterBarSelectButton
                    active={
                      filterData.filterDataQuery.Filter2.Id !== null &&
                      filterData.filterDataQuery.Filter2.Level === FilterLevel.ORGANISATION
                    }
                    label={t('dashboard.filters.organisation')}
                    onClick={() => getCompareData(FilterLevel.ORGANISATION)}
                  />
                  {client !== 'tno' && (
                    <>
                      <FilterBarSelectButton
                        active={filterData.filterDataQuery.Filter2.Level === FilterLevel.DIVISION}
                        label={t('dashboard.filters.division')}
                        onClick={() => getCompareData(FilterLevel.DIVISION)}
                      />
                      <FilterBarSelectButton
                        active={filterData.filterDataQuery.Filter2.Level === FilterLevel.BUSINESS_UNIT}
                        label={t('dashboard.filters.businessunit')}
                        onClick={() => getCompareData(FilterLevel.BUSINESS_UNIT)}
                      />
                      <FilterBarSelectButton
                        active={filterData.filterDataQuery.Filter2.Level === FilterLevel.UNIT}
                        label={t('dashboard.filters.unit')}
                        onClick={() => getCompareData(FilterLevel.UNIT)}
                      />
                      <FilterBarSelectButton
                        active={filterData.filterDataQuery.Filter2.Level === FilterLevel.TEAM}
                        label={t('dashboard.filters.team')}
                        onClick={() => getCompareData(FilterLevel.TEAM)}
                      />
                    </>
                  )}
                  <FilterBarSelectButton
                    active={filterData.filterDataQuery.Filter2.Level === FilterLevel.SELF}
                    label={t('dashboard.filters.yourself')}
                    onClick={() => getCompareData(FilterLevel.SELF)}
                  />
                </div>
              </div>
              <div className="column-row">
                <div className="date-selectors">
                  <span>{t('dashboard.filters.period')}</span>
                  <PeriodFilter
                    data={periodFilterOptions}
                    selectedValue={filterData.filterDataQuery.Filter2.DateFilters}
                    onChange={(selectedFilters: PeriodFilterOption[]) => {
                      changeFilterPeriod(selectedFilters);
                    }}
                  />
                </div>
              </div>
              <div className="column-row column-row__right">
                <button
                  className="button--stripped"
                  onClick={() => {
                    resetFilterData();
                  }}
                  type="button"
                >
                  {t('dashboard.filters.reset-filters')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
