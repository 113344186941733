import React from 'react';
import useBem from '@frontend/use-bem';

import DomainStatScore from './DomainStatScore';
import DomainStatistic from './Model/DomainStatistic';

const DomainStat = ({ comparisonScore, name, score, showScore }: DomainStatistic) => {
  const className = 'domain-stat';
  const { bemClassName } = useBem(className);

  return (
    <div
      className={bemClassName()}
      key={name}
    >
      <div className={bemClassName('name')}>{name}</div>
      <DomainStatScore
        score={score}
        comparisonScore={comparisonScore}
        showScore={showScore}
        rootClassName={className}
      />
    </div>
  );
};

export default DomainStat;
