import * as React from 'react';

import DashboardTitle from './DashboardTitle';

import './DashboardHeader.scss';

type PropType = {
  title: string;
  wideHeader?: boolean;
};

const DashboardHeader = ({ title, wideHeader = false }: PropType) => (
  <div className="content-container">
    <div className="dashboard-header">
      <DashboardTitle
        title={title}
        wideHeader={wideHeader!}
      />
    </div>
  </div>
);

export default DashboardHeader;
