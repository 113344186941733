import { FieldWrapper } from '@progress/kendo-react-form';
import { Error, FloatingLabel, Hint } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import React from 'react';

const FormInput = (fieldRenderProps: {
  [x: string]: any;
  validationMessage: any;
  touched: any;
  label: any;
  id: any;
  valid: any;
  disabled: any;
  hint: any;
  type: any;
  optional: any;
  max: any;
  value: any;
}) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max = 50,
    value,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper>
      <div className="k-form-field-wrap">
        <FloatingLabel
          label={label}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          editorValue={value}
        >
          <Input
            valid={valid}
            type={type}
            id={id}
            disabled={disabled}
            maxLength={max}
            ariaDescribedBy={`${hindId} ${errorId}`}
            {...others}
          />
        </FloatingLabel>
        {showHint && <Hint id={hindId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default FormInput;
