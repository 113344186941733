import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import useBem from '@frontend/use-bem';
import { useTranslation } from 'react-i18next';

const RoleCell = (subCategoryProps: GridCellProps) => {
  const { bemClassName, bemClassNames } = useBem('default-cell');
  const { dataItem } = subCategoryProps;
  const { Roles } = dataItem;
  const { t } = useTranslation();

  const roleList = Roles?.map((role: string) => t(`backoffice.roles.${role}`)).join(', ');
  return (
    <td
      className={bemClassNames(['k-table-td', bemClassName()])}
      colSpan={1}
    >
      <span title={roleList}>{roleList}</span>
    </td>
  );
};

export default RoleCell;
