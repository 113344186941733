import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import useTeams from '../../../../../Features/Dashboard/Hooks/useTeams';

const TeamDropdownList = (fieldRenderProps: FieldRenderProps) => {
  const { t } = useTranslation();
  const { label, id, valid, disabled, wrapperStyle, value, onBlur, onChange, onFocus } = fieldRenderProps;
  const { isLoading, data: teams } = useTeams();

  if (isLoading) {
    return null;
  }

  if (!isLoading && !teams?.find((team) => team.id === null))
    teams?.unshift({ name: t('backoffice.multi-select.no-team'), id: null });

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id="category"
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className="k-form-field-wrap">
        <DropDownList
          data={teams}
          textField="name"
          dataItemKey="id"
          defaultValue={teams ? teams[0] : null}
          value={teams?.find((team) => team.id === value)}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(selectedValue) => {
            // eslint-disable-next-line no-param-reassign
            selectedValue.value = selectedValue.value.id;
            onChange(selectedValue);
          }}
        />
      </div>
    </FieldWrapper>
  );
};

export default TeamDropdownList;
