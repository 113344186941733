import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { Upload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { useParams } from 'react-router-dom';
import useUser from '../../../Shared/Hooks/useUser';

const UploadUsers = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const { accessToken } = useUser();

  const [events, setEvents] = React.useState<Array<any>>([]);

  loadMessages(
    {
      upload: {
        cancel: 'Annuleer',
        clearSelectedFiles: 'Verwijderen',
        dropFilesHere: 'Sleep bestanden hierheen om te uploaden',
        headerStatusUploaded: 'Geuploaded',
        headerStatusUploading: 'Uploaden',
        invalidFileExtension: 'Ongeldige bestandsextensie',
        invalidFiles: 'Ongeldige bestandsextensie',
        invalidMaxFileSize: 'Bestand is te groot',
        invalidMinFileSize: 'Bestand is te klein',
        remove: 'Verwijder',
        retry: 'Probeer opnieuw',
        select: 'Selecteer',
        uploadSelectedFiles: 'Uploaden',
        total: 'Totaal',
        files: 'Bestanden',
      },
    },
    'nl-NL',
  );

  let language = 'en-US';

  if (i18n.language === 'nl') {
    language = 'nl-NL';
  }

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    const failedUsers: { email: string; error?: string }[] = [];

    if (event.response !== undefined && event.response.response.data !== undefined) {
      event.response.response.data.forEach((error: any) => {
        failedUsers.push({ email: error.EmailAddress, error: t(`uploadUsers.error-${error.ErrorCode}`)! });
      });
    }
    setEvents(failedUsers);
  };

  const odataUrl = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL;
  const coreAdministration = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;
  const saveUrl = `${odataUrl}${coreAdministration}/Customers/${customerId}/ImportUsers`;

  return (
    <>
      <h1>{t('uploadUsers.title')}</h1>
      <ul className="event-log">
        {events.length > 0 && (
          <>
            <li>
              <div className="item">
                <b>{t('uploadUsers.not-able-to-upload')}</b>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="email">
                  <strong>Email</strong>
                </div>
                <div className="reason">
                  <strong>Error</strong>
                </div>
              </div>
            </li>
          </>
        )}
        {events.map((event) => (
          <li>
            <div className="item">
              <div className="email">{event.email}</div>
              <div className="reason">{event.error}</div>
            </div>
          </li>
        ))}
      </ul>
      {events.length > 0 && (
        <div className="copy-event-log">
          <button
            type="button"
            onClick={() => navigator.clipboard.writeText(events.map((event) => event.email).join(', '))}
          >
            {t('uploadUsers.copy-emails')}
          </button>
        </div>
      )}
      <LocalizationProvider language={language}>
        <IntlProvider locale={language}>
          <Upload
            autoUpload={false}
            multiple={false}
            defaultFiles={[]}
            restrictions={{
              allowedExtensions: ['.csv', '.excel'],
            }}
            disabled={accessToken === undefined}
            onStatusChange={onStatusChange}
            withCredentials
            saveHeaders={{
              Authorization: `Bearer ${accessToken}`,
            }}
            saveUrl={saveUrl}
          />
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
};

export default UploadUsers;
