import * as React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import Level from '../../../../Enums/Level';

interface EditFormProps {
  cancelEdit: () => void;
  onSubmit: (event: any) => void;
  gridLayer?: Level;
  item: any;
}

const EditLayerForm = ({ gridLayer = undefined, item, cancelEdit, onSubmit }: EditFormProps) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { Name } = item;
  const { t } = useTranslation();
  const inputValidator = (value: string) =>
    value !== undefined && value !== '' ? '' : t('backoffice.errors.required-field');

  return (
    <Dialog
      className="pit-edit-form"
      title={Name ? `${t('backoffice.generic.manage')} ${Name}` : `${t('backoffice.generic.new-layer')}`}
      onClose={cancelEdit}
      width={700}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={({ allowSubmit }) => (
          <FormElement style={{ maxWidth: 650 }}>
            <fieldset className="k-form-fieldset">
              <div className="mb-3">
                <Label>{t('backoffice.layer.name')}</Label>
                <div className="k-form-field">
                  <Field
                    name="Name"
                    component={Input}
                    validator={inputValidator}
                  />
                </div>
              </div>
              {gridLayer === Level.ADMIN && client !== 'TNO' && (
                <div className="mb-3">
                  <Label>{t('backoffice.layer.feedback-url')}</Label>
                  <div className="k-form-field">
                    <Field
                      name="FeedbackUrl"
                      component={Input}
                    />
                  </div>
                </div>
              )}
              {gridLayer === Level.UNIT && client !== 'TNO' && (
                <div className="mb-3">
                  <Label>{t('backoffice.layer.code')}</Label>
                  <div className="k-form-field">
                    <Field
                      name="Code"
                      component={Input}
                      validator={inputValidator}
                    />
                  </div>
                </div>
              )}
            </fieldset>
            <div className="k-form-buttons">
              <button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!allowSubmit}
              >
                {t('backoffice.generic.update')}
              </button>
              <button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={cancelEdit}
              >
                {t('backoffice.generic.cancel')}
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default EditLayerForm;
