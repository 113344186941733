import React from 'react';

import { useTranslation } from 'react-i18next';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import DeactivateAccount from '../Components/DeactivateAccount';

const DeactivateAccountPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />

      <DeactivateAccount />
    </>
  );
};

export default DeactivateAccountPage;
