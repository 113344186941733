import React from 'react';

import { useTranslation } from 'react-i18next';

import '../SurveyQuestion.scss';
import classNames from 'classnames';

type PropType = {
  question: string;
  questionText: string;
};

const StartSurvey = (props: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;

  const { question, questionText } = props;
  const { i18n } = useTranslation();

  return (
    <div className={classNames('content-container survey--start', { tno: client === 'tno' })}>
      <div className="dashboard-header">
        <h1
          dangerouslySetInnerHTML={{ __html: question /* eslint-disable-line react/no-danger */ }}
          className="dashboard-header__title dashboard-header__title--medium"
        />
      </div>
      <div className="overview__intro no-border">
        <div className="paragraph paragraph__with-image">
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: questionText }} />

          {i18n.language === 'en' && (
            <img
              src={`/images/general/${client === 'tno' ? 'surveyStartTNO' : 'surveyStart'}.png`}
              alt="Survey start"
            />
          )}

          {i18n.language === 'nl' && (
            <img
              src={`/images/general/${client === 'tno' ? 'surveyStartTNODutch' : 'surveyStartDutch'}.png`}
              alt="Survey start"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StartSurvey;
