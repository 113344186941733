import React from 'react';
import { useTranslation } from 'react-i18next';

import '../About.scss';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import BackButton from '../Components/BackButton';

const WhatIsWellAtWorkPage = () => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;

  const { t, i18n } = useTranslation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />

      <div className="content-container">
        <div className="dashboard-header">
          <div className="dashboard-header__back">
            <BackButton />
          </div>
          <h1 className="dashboard-header__title dashboard-header__title--wide">
            <span>{t('information.whatIsWellAtWork.title')}</span>
          </h1>
        </div>
        <div className="overview__intro no-border">
          <div className="paragraph paragraph__with-image">
            <p>{t('information.whatIsWellAtWork.text')}</p>
            {i18n.language === 'en' && (
              <img
                src={`/images/general/${client === 'tno' ? 'surveyStartTNO' : 'surveyStart'}.png`}
                alt="Survey start"
              />
            )}

            {i18n.language === 'nl' && (
              <img
                src={`/images/general/${client === 'tno' ? 'surveyStartTNODutch' : 'surveyStartDutch'}.png`}
                alt="Survey start"
              />
            )}
          </div>
          <div className="paragraph">
            <p>{t('information.whatIsWellAtWork.text-2')}</p>
          </div>
          <div className="paragraph">
            <p>{t('information.whatIsWellAtWork.text-3')}</p>
          </div>
          <div className="paragraph">
            <p>{t('information.whatIsWellAtWork.text-4')}</p>
          </div>
          {client === 'tno' && (
            <>
              <div className="paragraph">
                <p>{t('information.whatIsWellAtWork.text-5')}</p>
              </div>
              <div className="paragraph">
                <p>{t('information.whatIsWellAtWork.text-6')}</p>
                <ul>
                  <li>
                    <a
                      href="https://www.youtube.com/watch?v=HDXS7u2sUD4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('information.whatIsWellAtWork.link-1')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/watch?v=FbE8BarvOfs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('information.whatIsWellAtWork.link-2')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://doi.org/10.3390/ijerph19158925"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('information.whatIsWellAtWork.link-3')}
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default WhatIsWellAtWorkPage;
