import React from 'react';
import { ReactSVG } from 'react-svg';

import Icon from '../../Enums/Icon';

import './IconRender.scss';

const IconColorMapping = {
  [Icon.chevronLeft]: ['fill'],
  [Icon.chevronRight]: ['fill'],
};

type PropType = {
  icon: Icon;
  color?: string;
  width?: string;
  height?: string;
  className?: string;
};

const IconRender = ({ icon, color = 'var(--black)', width = '20px', height = '20px', className = '' }: PropType) => {
  // Set the width, height and color of the SVG.
  const handleSvgBeforeInjection = (svg: SVGElement) => {
    svg.setAttribute('width', width || '0');
    svg.setAttribute('height', height || '0');

    if (color && IconColorMapping[icon]) {
      const paths = svg.querySelectorAll('path');
      if (paths) {
        Array.from(paths).forEach((path) => {
          IconColorMapping[icon].forEach((attribute) => {
            path.setAttribute(attribute, color);
          });
        });
      }
    }
  };

  return (
    <ReactSVG
      src={`/images/icons/${icon}.svg`}
      wrapper="span"
      className={`${className} icon`}
      beforeInjection={handleSvgBeforeInjection}
      style={{
        width,
        height,
      }}
    />
  );
};

export default IconRender;
