import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../Shared/Components/Loader/Loader';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import Level from '../../../Shared/Enums/Level';
import useUser from '../../../Shared/Hooks/useUser';
import useOrganisation from '../../Dashboard/Hooks/useOrganisation';
import ManagementPageHeader from './components/ManagementPageHeader/ManagementPageHeader';
import DataGridSelector from '../../../Shared/Components/DataGridSelector/DataGridSelector';
import { SelectedGridEnum } from '../../../Shared/Components/DataGridSelector/types';
import UserDataGrid from '../../../Shared/Components/DataGrid/UserDataGrid';
import LayerDataGrid from '../../../Shared/Components/DataGrid/LayerDataGrid';

import '../management.scss';

const OrganisationsManagementPage = () => {
  const { t } = useTranslation();
  const [selectedGrid, setSelectedGrid] = useState<SelectedGridEnum>(SelectedGridEnum.LayerDetails);
  const { userInfo } = useUser();
  const navigate = useNavigate();
  const { organisationId } = useParams();

  const { isLoading: organisationLoading, data: organisation } = useOrganisation(organisationId!);

  if (organisationLoading) {
    return (
      <div className="loader-position">
        <Loader />
      </div>
    );
  }

  if (userInfo && userInfo.checkPermission('write:organisations:customer')) {
    return (
      <>
        <NavigationBar
          hasTitle
          hasBorder={false}
          menuTitle={t('dashboard.general.menu-title')}
          showProfile
        />
        <div className="content-container">
          <ManagementPageHeader
            title={organisation ? organisation[0].name : t('management.Organisation.title')}
            gridLayer={Level.ORGANISATION}
          />
          <DataGridSelector
            selectedGrid={selectedGrid}
            onGridSelect={(grid) => setSelectedGrid(grid)}
            gridLayer={Level.ORGANISATION}
          />
          {selectedGrid === SelectedGridEnum.LayerDetails ? (
            <LayerDataGrid
              gridLayer={Level.ORGANISATION}
              removePermission="write:organisations:customer"
            />
          ) : (
            <UserDataGrid level={Level.ORGANISATION} />
          )}
        </div>
      </>
    );
  }

  if (userInfo) {
    navigate('/dashboard');
  }

  return (
    <div className="loader-position">
      <Loader />
    </div>
  );
};

export default OrganisationsManagementPage;
