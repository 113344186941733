import React from 'react';
import { useTranslation } from 'react-i18next';
import useSurvey from '../Hooks/useSurvey';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import StartSurvey from '../Components/StartSurvey/StartSurvey';
import SurveyQuestions from '../Components/SurveyQuestions/SurveyQuestions';
import Footer from '../../../Shared/Components/Footer/FooterRow';
import Loader from '../../../Shared/Components/Loader/Loader';

type PropType = {
  surveyId: string;
  questionNumber: number;
  showIntro: boolean;
  showMenu: boolean;
  showLanguageSelectDuringSurvey: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
};

const SurveyRender = ({
  surveyId,
  questionNumber,
  showMenu,
  showIntro,
  showLanguageSelectDuringSurvey,
  handlePrevious,
  handleNext,
}: PropType) => {
  const { t } = useTranslation();
  const { isLoading, data } = useSurvey(surveyId);

  if (isLoading) return <Loader />;

  const totalQuestions = data?.getQuestionCount() ?? 0;

  return (
    <>
      <NavigationBar
        menuTitle=""
        totalQuestions={totalQuestions}
        currentQuestion={questionNumber}
        showProfile
        showMenu={showMenu}
        showContentButtons
        showLanguage={showLanguageSelectDuringSurvey}
      />
      {showIntro ? (
        <div className="survey-section">
          <StartSurvey
            question={t('survey.intro-title')}
            questionText={t('survey.intro-description')}
          />
        </div>
      ) : null}
      <SurveyQuestions
        survey={data!}
        surveyId={surveyId}
        questionNumber={questionNumber}
        totalQuestions={totalQuestions}
        onHandlePrevious={() => handlePrevious()}
        onHandleNext={() => handleNext()}
      />
      {questionNumber === 0 && <Footer />}
    </>
  );
};

export default SurveyRender;
