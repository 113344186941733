import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Shared/Components/Loader/Loader';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import Level from '../../../Shared/Enums/Level';
import useUser from '../../../Shared/Hooks/useUser';
import DataGridSelector from '../../../Shared/Components/DataGridSelector/DataGridSelector';
import { SelectedGridEnum } from '../../../Shared/Components/DataGridSelector/types';
import ManagementPageHeader from './components/ManagementPageHeader/ManagementPageHeader';
import UserDataGrid from '../../../Shared/Components/DataGrid/UserDataGrid';
import LayerDataGrid from '../../../Shared/Components/DataGrid/LayerDataGrid';

import '../management.scss';

const OrganisationsManagementPage = () => {
  const { t } = useTranslation();
  const [selectedGrid, setSelectedGrid] = useState<SelectedGridEnum>(SelectedGridEnum.LayerDetails);

  const { userInfo } = useUser();
  const navigate = useNavigate();

  if (userInfo && userInfo.checkPermission('write:customers:all')) {
    return (
      <>
        <NavigationBar
          hasTitle
          hasBorder={false}
          menuTitle={t('dashboard.general.menu-title')}
          showProfile
        />
        <div className="content-container">
          <ManagementPageHeader
            title={t('management.Customers.title')}
            gridLayer={Level.ADMIN}
          />
          <DataGridSelector
            selectedGrid={selectedGrid}
            onGridSelect={(grid) => setSelectedGrid(grid)}
            gridLayer={Level.ADMIN}
            disableLinkedUsers
          />
          {selectedGrid === SelectedGridEnum.LayerDetails ? (
            <LayerDataGrid
              gridLayer={Level.ADMIN}
              removePermission="write:customers:all"
            />
          ) : (
            <UserDataGrid level={Level.ADMIN} />
          )}

          <div className="list-grid-navigation" />
        </div>
      </>
    );
  }

  if (userInfo) {
    navigate('/dashboard');
  }

  return (
    <div className="loader-position">
      <Loader />
    </div>
  );
};

export default OrganisationsManagementPage;
