import React from 'react';
import { useTranslation } from 'react-i18next';

import '../UploadUsers.scss';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import UploadUsers from '../Components/UploadUsers';
import UploadUsersInformation from '../Components/UploadUsersInformation';

const UploadUsersPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />

      <div className="content-container">
        <div className="upload-users">
          <UploadUsers />

          <UploadUsersInformation />
        </div>
      </div>
    </>
  );
};

export default UploadUsersPage;
