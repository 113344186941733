import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import PeriodFilterOption from '../../../../../Models/Dashboard/PeriodFilterOption';

import './PeriodFilter.scss';

type PropType = {
  onChange: (item: PeriodFilterOption[]) => void;
  selectedValue?: PeriodFilterOption[];
  data: PeriodFilterOption[];
};

const PeriodFilter = ({ onChange, data, selectedValue = undefined }: PropType) => {
  const { t } = useTranslation();

  return (
    <div className="period-filter">
      <Select
        placeholder={t('dashboard.period-placeholder')}
        isSearchable={false}
        value={selectedValue}
        classNamePrefix="react-select"
        options={data}
        isClearable
        isMulti
        onChange={(value): void => onChange(value as PeriodFilterOption[])}
      />
    </div>
  );
};

export default PeriodFilter;
