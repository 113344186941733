import React, { useEffect, useState } from 'react';

import './UserSettings.scss';

import { useTranslation } from 'react-i18next';

import Button from '../../../Shared/Components/Button/Button';
import Modal from './Modal';
import resetPassword from '../Data/UserService';
import useUser from '../../../Shared/Hooks/useUser';

const SetPassword = () => {
  const [resetTriggered, setResetTriggered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { user } = useUser();
  const { t } = useTranslation();

  const handleClicked = () => {
    resetPassword(user?.email!).then(() => {
      setResetTriggered(true);
      setShowModal(!showModal);
    });
  };

  useEffect(() => {}, [showModal]);

  return (
    <div className="content-container">
      <div className="user-settings">
        <h1>{t('usersettings.edit-password.title')}</h1>
        <p>{t('usersettings.edit-password.description')}</p>

        <Button
          disabled={resetTriggered}
          onClick={() => handleClicked()}
          label={t('usersettings.edit-password.button')!}
        />
      </div>
      <Modal
        showModal={showModal}
        modalTitle={t('usersettings.modal.email-sent-title')}
        modalDescription={t('usersettings.modal.email-sent-description')}
        modalButton={t('usersettings.modal.email-sent-button')}
        onCloseModal={() => setShowModal(false)}
      />
    </div>
  );
};

export default SetPassword;
