import React from 'react';

import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import SurveyScore from '../../../Models/Dashboard/SurveyScore';
import Loader from '../Loader/Loader';

import './spiderweb.scss';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export type PropType = {
  legendLabelOne: string;
  legendLabelTwo: string;
  statistics: SurveyScore[] | undefined | null;
  isLoading: boolean;
  isHR: boolean;
  filterOneParticipants?: number;
  filterOneUserCount?: number;
  filterTwoParticipants?: number;
  filterTwoUserCount?: number;
  showStartSurveyButton?: boolean;
};

const RadarChart = ({
  legendLabelOne,
  legendLabelTwo,
  statistics,
  isLoading,
  isHR,
  filterOneParticipants = 0,
  filterOneUserCount = 0,
  filterTwoParticipants = 0,
  filterTwoUserCount = 0,
  showStartSurveyButton = false,
}: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { t } = useTranslation();

  let chartLabels: [
    [string, string],
    [string, string],
    [string, string],
    [string, string],
    [string, string],
    [string, string],
    [string, string],
  ];
  let fontsize: number;

  function loadChartText() {
    if (client === 'tno') {
      chartLabels = [
        [t('domains.MeaningfulnessAndMotivationOne'), t('domains.MeaningfulnessAndMotivationTwo')],
        [t('domains.SelfManagementOne'), t('domains.SelfManagementTwo')],
        [t('domains.JobCraftingOne'), t('domains.JobCraftingTwo')],
        [t('domains.MentalAndPhysicalCapacityOne'), t('domains.MentalAndPhysicalCapacityTwo')],
        [t('domains.WorkEngagementAndExtraRoleBehaviourOne'), t('domains.WorkEngagementAndExtraRoleBehaviourTwo')],
        [t('domains.PositiveWorkClimateOne'), t('domains.PositiveWorkClimateTwo')],
        [t('domains.AbsenceOfStressSignalsOne'), t('domains.AbsenceOfStressSignalsTwo')],
      ];
    } else {
      chartLabels = [
        [t('domains.NeedsFulfilmentOne'), t('domains.NeedsFulfilmentTwo')],
        [t('domains.SelfManagementOne'), t('domains.SelfManagementTwo')],
        [t('domains.WorkDesignOne'), t('domains.WorkDesignTwo')],
        [t('domains.energyOne'), t('domains.energyTwo')],
        [t('domains.MentalAndPhysicalCapacityOne'), t('domains.MentalAndPhysicalCapacityTwo')],
        [t('domains.PsychologicalSafetyOne'), t('domains.PsychologicalSafetyTwo')],
        [t('domains.WorkExperienceOne'), t('domains.WorkExperienceTwo')],
      ];
    }
    if (window.innerWidth < 1024) {
      fontsize = 10;
    } else {
      fontsize = 14;
    }
  }

  loadChartText();

  function resizeChart() {
    loadChartText();
  }

  const options = {
    onResize: resizeChart,
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      r: {
        max: 10,
        min: 0,
        ticks: {
          stepSize: 2,
          backdropColor: '#f0f2f7',
          display: client !== 'tno',
        },
        pointLabels: {
          color: '#30333a',
          font: {
            size: fontsize!,
            family: 'Aventa-Medium',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const datasets: any = [];

  if (!isLoading && statistics !== null && statistics !== undefined) {
    statistics.forEach((statistic, index) => {
      switch (index) {
        case 0:
          datasets.push({
            label: legendLabelOne,
            data: statistic.getRadarChartValues(),
            backgroundColor: 'rgba(79, 144, 218, 0.38)',
            borderColor: 'rgb(25, 72, 103)',
            borderWidth: 2,
            pointBackgroundColor: 'rgb(25, 72, 103)',
          });
          break;
        case 1:
          datasets.push({
            label: legendLabelTwo,
            data: statistics[1]?.getRadarChartValues(),
            backgroundColor: 'rgba(188, 234, 170, 0.4)',
            borderColor: 'rgb(137, 208, 109)',
            borderWidth: 2,
            pointBackgroundColor: 'rgb(137, 208, 109)',
          });
          break;
        default:
          return null;
      }
      return null;
    });
  }

  const graphNotAvailable: boolean =
    !isLoading && (statistics === null || statistics === undefined || (statistics && statistics[0].domains.length < 1));

  return (
    <div className={classNames('spiderweb', { 'spiderweb--wide': graphNotAvailable })}>
      {!isLoading && statistics !== null && statistics !== undefined && statistics[0]?.domains.length > 0 && (
        <>
          <Radar
            data={{
              labels: chartLabels!,
              datasets,
            }}
            options={options}
          />
          <div className="spiderweb__content">
            <div className="content-top-left">
              <div className="title">
                <h3>{t('dashboard.radar.title')}</h3>
              </div>
              <div className="legend__info-row">
                {isHR && (
                  <>
                    {client === 'tno' ? (
                      <p>
                        {`${legendLabelOne} (${Math.round((filterOneParticipants / filterOneUserCount) * 100)}% ${t(
                          'dashboard.radar.legend.participation',
                        )})`}
                      </p>
                    ) : (
                      <p>
                        {`${legendLabelOne} (${t('dashboard.radar.legend.participation', {
                          participants: filterOneParticipants,
                          max: filterOneUserCount,
                        })})`}
                      </p>
                    )}
                    {legendLabelTwo !== t('dashboard.filters.all') && (
                      <p className="green">
                        {datasets.length === 2 && client === 'tno' && (
                          <span>{`${legendLabelTwo} (${Math.round(
                            (filterTwoParticipants / filterTwoUserCount) * 100,
                          )}% ${t('dashboard.radar.legend.participation')})`}</span>
                        )}
                        {datasets.length === 2 && client !== 'tno' && (
                          <span>
                            {`${legendLabelTwo} (${t('dashboard.radar.legend.participation', {
                              participants: filterTwoParticipants,
                              max: filterTwoUserCount,
                            })})`}
                          </span>
                        )}
                        {datasets.length === 1 && (
                          <span>{`${legendLabelTwo} - ${t('dashboard.radar.legend.no-data')}`}</span>
                        )}
                      </p>
                    )}
                  </>
                )}
                {!isHR && (
                  <>
                    <p>{legendLabelOne}</p>
                    {legendLabelTwo !== t('dashboard.filters.all') && (
                      <p className="green">
                        {datasets.length === 2 && <span>{legendLabelTwo}</span>}
                        {datasets.length === 1 && (
                          <span>{`${legendLabelTwo} - ${t('dashboard.radar.legend.no-data')}`}</span>
                        )}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            {client === 'tno' && (
              <div className="content-bottom-left">
                <div className="content-legend">
                  <div className="content-legend__item">
                    <p>
                      <span>{t('dashboard.radar.legend.first.description')}</span>
                    </p>
                  </div>
                  <div className="content-legend__item">
                    <p>
                      <span>{t('dashboard.radar.legend.second.description')}</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {client === 'wellatwork' && (
              <div className="content-bottom-left">
                <div className="content-legend">
                  <div className="content-legend__item">
                    <p>
                      <span className="score">{t('dashboard.radar.legend.first.number')}</span>
                      <span>{t('dashboard.radar.legend.first.description')}</span>
                    </p>
                  </div>
                  <div className="content-legend__item">
                    <p>
                      <span className="score">{t('dashboard.radar.legend.second.number')}</span>
                      <span>{t('dashboard.radar.legend.second.description')}</span>
                    </p>
                  </div>
                  <div className="content-legend__item">
                    <p>
                      <span className="score">{t('dashboard.radar.legend.third.number')}</span>
                      <span>{t('dashboard.radar.legend.third.description')}</span>
                    </p>
                  </div>
                  <div className="content-legend__item">
                    <p>
                      <span className="score">{t('dashboard.radar.legend.fourth.number')}</span>
                      <span>{t('dashboard.radar.legend.fourth.description')}</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {graphNotAvailable && (
        <div className="no-data">
          <h3>{t('dashboard.radar.noData')}</h3>
          <p>
            {!isHR && t('dashboard.radar.noDataDescriptionEmployee')}
            {isHR && t('dashboard.radar.noDataDescription')}
          </p>
          {showStartSurveyButton && (
            <a
              href="/survey/wellbeing"
              className="take-survey-button"
            >
              {t('menu.start-survey')}
            </a>
          )}
        </div>
      )}

      {isLoading && (
        <div className="loader-position">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default RadarChart;
