/* eslint-disable react/no-danger */

import React from 'react';

import './QuestionTitle.scss';

type PropType = {
  title: string;
};

const QuestionTitle = ({ title }: PropType) => (
  <h1
    className="question-title"
    dangerouslySetInnerHTML={{ __html: title }}
  />
);

export default QuestionTitle;
