import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SurveyAvailability from '../../../Models/Survey/SurveyAvailability';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import useAvailability from '../../Survey/Hooks/useAvailability';
import DashboardHeader from '../Components/DashboardHeader/DashboardHeader';
import DashboardOverview from '../Components/DashboardOverview/DashboardOverview';
import useUser from '../../../Shared/Hooks/useUser';

const DashboardPage = () => {
  const { t } = useTranslation();

  const { data } = useAvailability();
  const { userInfo } = useUser();
  const [retakeSurvey, setRetakeSurvey] = useState(false);

  useEffect(() => {
    data?.forEach((survey: SurveyAvailability) => {
      if (survey.surveyId === window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID && survey.isAvailable) {
        setRetakeSurvey(true);
      }
    });
  }, [data]);

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
        showStartSurveyButton={retakeSurvey}
      />
      <DashboardHeader
        title={t('dashboard.employee.title')}
        wideHeader
      />
      <DashboardOverview
        showStartSurveyButton={retakeSurvey}
        userInfo={userInfo!}
      />
    </>
  );
};

export default DashboardPage;
