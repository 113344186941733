import React from 'react';

import { useTranslation } from 'react-i18next';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import SetNickname from '../Components/SetNickname';

const SetNicknamePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />

      <SetNickname />
    </>
  );
};

export default SetNicknamePage;
