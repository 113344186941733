import * as React from 'react';
import useBem from '@frontend/use-bem';
import Domain from '../../../../Models/Dashboard/Domain';
import SurveyScore from '../../../../Models/Dashboard/SurveyScore';
import DomainInfo from '../../../../Shared/Components/DomainInfo/DomainInfo';
import DomainStatistic from '../../../../Shared/Components/DomainInfo/Model/DomainStatistic';
import Loader from '../../../../Shared/Components/Loader/Loader';
import LanguageHelper from '../../../../Utils/LanguageHelper';

import './DetailedStatistics.scss';

type PropType = {
  showStats: boolean;
  statistics: SurveyScore[];
  isLoading: boolean;
};

const DomainOverview = ({ showStats, statistics, isLoading }: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { bemClassName, bemClassNames } = useBem('detailed-statistics');
  let comparisonScore: number = 0;

  function renderDomainDetails(domain: Domain, indexOne: number) {
    const scores: DomainStatistic[] = [];

    if (statistics?.length > 1 && statistics[1].domains.length > 0) {
      domain.subdomainScores.forEach((subdomain, indexTwo: number) => {
        scores.push({
          name: LanguageHelper.SelectLanguage(domain.subdomainLabels[indexTwo].subdomainLabel),
          score: subdomain.value!,
          comparisonScore: statistics![1].domains[indexOne].subdomainScores[indexTwo].value,
        });
      });

      comparisonScore = statistics![1].domains[indexOne].domainScore.value!;
    } else {
      domain.subdomainScores.forEach((subdomain, index) => {
        scores.push({
          name: LanguageHelper.SelectLanguage(domain.subdomainLabels[index].subdomainLabel),
          score: subdomain.value!,
        });
      });
    }

    return (
      <DomainInfo
        value={domain.domainScore.value!}
        comparisonScore={comparisonScore}
        name={LanguageHelper.SelectLanguage(domain.domainLabel)}
        description={LanguageHelper.SelectLanguage(domain.domainDescription)}
        stats={scores}
        showStats={showStats}
        key={domain.domainScore.key!}
      />
    );
  }

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, 'tno', client === 'tno')])}>
      {!isLoading && statistics && statistics![0].domains.map(renderDomainDetails)}
      {isLoading && (
        <div className="loader-position">
          <Loader />
        </div>
      )}
    </div>
  );
};
export default DomainOverview;
