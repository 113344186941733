import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../Shared/Components/Loader/Loader';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import useUser from '../../../Shared/Hooks/useUser';
import DashboardHeader from '../Components/DashboardHeader/DashboardHeader';
import DashboardOverviewEmployer from '../Components/DashboardOverview/DashboardOverviewEmployer';

const DashboardPageEmployer = () => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const { userInfo } = useUser();
  const navigate = useNavigate();

  // CHECK-RIGHT teamlead
  if (userInfo && userInfo.checkPermission('read:organisations:customer')) {
    let title = `${t('dashboard.manager.title')}`;
    if (client !== 'tno') {
      const secondPartOfTitle = pathname === '/hr-dashboard' ? 'hr' : 'teamlead';
      title = `${title} ${t(`dashboard.manager.${secondPartOfTitle}`)}`;
    }

    return (
      <>
        <NavigationBar
          hasTitle
          hasBorder={false}
          menuTitle={t('dashboard.general.menu-title')}
          showProfile
        />
        <DashboardHeader
          title={title}
          wideHeader
        />
        <DashboardOverviewEmployer userInfo={userInfo} />
      </>
    );
  }

  // CHECK-RIGHT Employee
  if (userInfo) {
    navigate('/dashboard');
  }

  return (
    <div className="loader-position">
      <Loader />
    </div>
  );
};

export default DashboardPageEmployer;
