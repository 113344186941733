import React, { useEffect, useState } from 'react';
import useBem from '@frontend/use-bem';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import downloadFile from '../../../../Utils/DownloadFile';
import Checkbox from '../../../../Shared/Components/Checkbox/Checkbox';
import Icon from '../../../../Shared/Enums/Icon';
import IconButton from '../../../../Shared/Components/Button/IconButton';
import { updateUser } from '../../../../Shared/Data/GetUserService';
import useUser from '../../../../Shared/Hooks/useUser';

import './SurveyNavigation.scss';

type PropType = {
  labelNext: string;
  onHandleNext: () => void;
  onHandlePrevious: () => void;
  firstQuestion: boolean;
  labelPrev: string;
  nextDisabled: boolean;
  lastQuestion: boolean;
};

const SurveyNavigation = (props: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const user = useUser();
  const { bemClassName } = useBem('survey-navigation');
  const { bemClassName: bemClassNamePrivacy, bemClassNames: bemClassNamesPrivacy } = useBem('privacy-policy');

  const { labelPrev, labelNext, onHandleNext, onHandlePrevious, firstQuestion, nextDisabled, lastQuestion } = props;
  const { t, i18n } = useTranslation();
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState<boolean>(false);

  let label = labelNext;

  if (firstQuestion) {
    label = t('survey.start');
  } else if (lastQuestion) {
    label = t('survey.submit');
  }

  function handleDownloadPrivacyStatement(): void {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NAME_EN,
      );
    }
  }

  function handleDownloadConsentForm(): void {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_FILE_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_FILE_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_FILE_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_FILE_NAME_EN,
      );
    }
  }

  function handleDownloadTermsAndConditions(): void {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_NAME_EN,
      );
    }
  }

  function handleDownloadTermsAndConditionsTno(): void {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_TNO_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_TNO_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_TNO_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_TNO_NAME_EN,
      );
    }
  }

  const handleTermsAndConditions = () => {
    if (!user?.userInfo?.userId) return;

    updateUser(user?.userInfo?.userId, { AcceptedTerms: !acceptedTermsAndConditions }).then(() => {
      setAcceptedTermsAndConditions(!acceptedTermsAndConditions);
      localStorage.setItem('acceptedTerms', !acceptedTermsAndConditions ? 'true' : 'false');
    });
  };

  useEffect(() => {
    if (localStorage.getItem('acceptedTerms')) {
      setAcceptedTermsAndConditions(localStorage.getItem('acceptedTerms') === 'true');

      return;
    }

    if (user?.userInfo) {
      setAcceptedTermsAndConditions(user.userInfo.acceptedTerms);
    }
  }, [user?.userInfo]);

  return (
    <div className="content-container">
      <div className={bemClassName()}>
        <div className={bemClassName('content')}>
          {firstQuestion && (
            <div
              className={bemClassNamesPrivacy([
                bemClassNamePrivacy(),
                bemClassNamePrivacy(null, 'tno', client === 'tno'),
              ])}
            >
              <Checkbox
                boxChecked={acceptedTermsAndConditions}
                onToggleChecked={() => {
                  handleTermsAndConditions();
                }}
                label=""
                checkboxId="privacy"
              />
              <div className="privacy-policy__statements">
                {client === 'tno' ? (
                  <p>
                    <Trans
                      i18nKey="survey.terms-and-conditions"
                      components={{
                        1: <br />,
                        2: (
                          // eslint-disable-next-line jsx-a11y/control-has-associated-label
                          <button
                            onClick={handleDownloadTermsAndConditionsTno}
                            type="button"
                          />
                        ),
                        3: (
                          // eslint-disable-next-line jsx-a11y/control-has-associated-label
                          <button
                            onClick={handleDownloadPrivacyStatement}
                            type="button"
                          />
                        ),
                      }}
                    />
                  </p>
                ) : (
                  <>
                    <p>
                      <Trans
                        i18nKey="survey.terms-and-conditions"
                        components={{
                          1: <br />,
                          2: (
                            // eslint-disable-next-line jsx-a11y/control-has-associated-label
                            <button
                              onClick={handleDownloadConsentForm}
                              type="button"
                            />
                          ),
                          3: (
                            // eslint-disable-next-line jsx-a11y/control-has-associated-label
                            <button
                              onClick={handleDownloadTermsAndConditions}
                              type="button"
                            />
                          ),
                        }}
                      />
                    </p>
                    <p>
                      <Trans
                        i18nKey="survey.privacy-statement"
                        components={{
                          1: <br />,
                          2: (
                            // eslint-disable-next-line jsx-a11y/control-has-associated-label
                            <button
                              onClick={handleDownloadPrivacyStatement}
                              type="button"
                            />
                          ),
                        }}
                      />
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="buttons">
            <button
              className={classNames('button--stripped', { 'button--stripped hide': firstQuestion })}
              onClick={onHandlePrevious}
              type="button"
            >
              {labelPrev}
            </button>
            <IconButton
              onClick={onHandleNext}
              icon={Icon.chevronRight}
              label={label}
              disabled={(!acceptedTermsAndConditions && firstQuestion) || nextDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyNavigation;
